<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuer Benutzer">
      <user-form
        :user="user"
        submit-label="Neuen Benutzer anlegen"
        :form-submitted="saveUser"
        mode="new"
      ></user-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import UserForm from "@/components/forms/UserForm"
import UsersRepository from "@/repositories/users_repository.js"

export default {
  metaInfo: {
    title: "Benutzer erstellen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    UserForm
  },
  data() {
    return {
      user: {
        region_roles: {}
      }
    }
  },
  async created() {},
  methods: {
    async saveUser(user) {
      await UsersRepository.invite(user)
      this.$router.push({ name: "UsersPage" })
    }
  }
}
</script>
