import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/users"

export default {
  async getAll(params) {
    let response = await Repository.get(resource, {
      params: { ...params, ...{ region_id: store.state.auth.chosenRegion } }
    })
    return response.data
  },
  async get(userID) {
    let response = await Repository.get(`${resource}/${userID}`)
    return response.data.user
  },
  async update(userID, userData) {
    let response = await Repository.patch(`${resource}/${userID}`, {
      user: userData
    })
    return response.data
  },
  async destroy(userID) {
    let response = await Repository.delete(`${resource}/${userID}`)
    return response.data
  },
  async invite(userData) {
    let response = await Repository.post(`${resource}/invite`, {
      region_id: store.state.auth.chosenRegion,
      user: userData
    })
    return response.data
  }
}
