<template>
  <base-form
    v-slot="slotProps"
    :model="user"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Benutzer"
  >
    <el-form-item label="E-Mail-Adresse" prop="email">
      <el-input v-model.trim="slotProps.model.email" v-focus></el-input>
    </el-form-item>

    <el-form-item
      v-if="!persisted || (persisted && userHasRegion)"
      label="Name"
      prop="name"
    >
      <el-input v-model="slotProps.model.name"></el-input>
    </el-form-item>

    <el-form-item
      v-if="superAdminMode && (!persisted || (persisted && userHasRegion))"
      label="Region"
      prop="region_id"
    >
      <el-select
        v-model="slotProps.model.region_id"
        v-loading="loadingRegions"
        style="width: 100%;"
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in regions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="!persisted || (persisted && userHasRegion)"
      label="Administrator der Region"
    >
      <el-switch v-model="slotProps.model.region_roles.admin"> </el-switch>
    </el-form-item>

    <el-form-item
      v-show="
        !slotProps.model.region_roles.admin &&
          (!persisted || (persisted && userHasRegion))
      "
      label="Verwaltungsrechte"
      class="checkbox-item"
    >
      <el-checkbox
        v-for="item in privileges"
        :key="item.value"
        v-model="slotProps.model.region_roles[item.value]"
      >
        {{ item.label }}
      </el-checkbox>
    </el-form-item>
  </base-form>
</template>

<script>
import { mapGetters } from "vuex"
import BaseForm from "@/components/forms/BaseForm"
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    BaseForm
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    user: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    superAdminMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "Bitte Namen angeben",
            trigger: "blur"
          }
        ],
        region_id: [
          {
            required: true,
            message: "Bitte Region auswählen",
            trigger: "change"
          }
        ]
      },
      loadingRegions: false,
      regions: [],
      privileges: [
        { label: "Veranstaltungen", value: "events_manager" },
        { label: "Einrichtungen", value: "institutions_manager" },
        { label: "Artikel", value: "articles_manager" },
        { label: "Videos", value: "videos_manager" },
        { label: "Neuigkeiten", value: "news_manager" },
        { label: "Downloads", value: "downloads_manager" },
        { label: "Wichtige Nummern", value: "numbers_manager" },
        { label: "Analytics", value: "analytics_manager" }
      ]
    }
  },
  computed: {
    persisted() {
      return this.user.hasOwnProperty("id")
    },
    userHasRegion() {
      return !this.user.organization && !this.user.super_admin
    },
    ...mapGetters("auth", ["isSuperAdmin"])
  },
  async created() {
    if (this.isSuperAdmin) {
      this.loadingRegions = true

      try {
        this.regions = await RegionsRepository.getRegions()
        this.loadingRegions = false
      } catch (error) {
        this.loadingRegions = false
        this.handleApiError(error, "Fehler Regionen")
      }
    }
  },
  methods: {}
}
</script>
